import React from "react"
import styled from "styled-components"
import SEO from "components/seo"

const Container = styled.div`
  background-color: ${(prop) => prop.theme.lightBackgroundColor};
  margin: 0rem 0 5rem;
  text-align: center;
`

const RequestDemoPage = () => (
  <Container>
    <SEO
      title="Sourcery | Schedule a Demo"
      description="Learn how Sourcery can help your team write cleaner code, faster, and increase your overall velocity."
    />
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSfGeIyrS9jPrqqRkbDz2ADmbbyhKqNlYy98bong0JJtBfgZew/viewform?embedded=true"
      width="640"
      height="830"
      frameBorder="0"
      marginHeight="0"
      marginWidth="0"
    >
      Loading…
    </iframe>
  </Container>
)

export default RequestDemoPage
